@import '../../../styles/utilities';

.header {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  height: 74px;
  padding-top: 10px;
  z-index: 100;

  .page_header_inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    //&:before {
    //  content: '';
    //  height: 1px;
    //  border-bottom: 0 solid black;
    //  width: calc(100% - (44px * 2));
    //  top: 0;
    //  left: 44px;
    //  position: absolute;
    //}
    //
    //&:after {
    //  content: '';
    //  height: 1px;
    //  border-bottom: 1px solid black;
    //  width: calc(100% - (44px * 2));
    //  top: auto;
    //  bottom: 0;
    //  left: 44px;
    //  position: absolute;
    //}
  }

  .logo_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 23px;
    margin-left: 18px;
    max-height: 100%;

    img {
      display: block;
      margin: auto 0;
      width: auto;
      max-height: 100%;
    }
  }

  .nav_menu {
    height: 100%;
    margin-left: auto;

    ul {
      margin: 0 !important;
      padding: 0;
      list-style: none;
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      white-space: nowrap;

      li {
        height: 100%;
        margin: 0 18px;

        &:first-child {
          margin-left: 0;
        }

        a {
          position: relative;
          display: flex;
          align-items: center;
          height: 100%;
          font-family: "Dm Sans", sans-serif;
          font-size: 15px;
          line-height: 1.6em;
          font-weight: 400;
          text-decoration: none;

          &.text-gray {
            color: $menuColor;
          }
        }
      }
    }
  }
}