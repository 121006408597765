.footer_card {
  background: #F9F9F9;
  margin-bottom: 5px;
  padding: 20px;

  @media screen and (min-width: 640px) {
    margin-bottom: 15px;
    padding: 15px 40px 20px 26px;
  }

  .label {
    font-size: 18px;
    line-height: 1;
  }

  .number {
    font: {
      size: 40px;
      weight: 500;
    };
    line-height: 1.5;

    &.smaller {
      font-size: 30px;
      line-height: 1.2;
    }
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.grid_card {
  width: 100%;
  @media screen and (min-width: 640px) {
    margin: 0 12px 12px 0;
    width: calc(33.33% - 12px);
  }
}

.flex_icon {
  align-items: center;
  display: flex;
}