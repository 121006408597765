@import 'utilities';

.homepage-container {
  margin-top: 50px;
  min-height: calc(100vh - 128px);

  @media #{$lg} {
    margin-top: 96px;
  }

  h1 {
    font-size: 30px;
    letter-spacing: normal;
    line-height: 1;
    margin-bottom: 25px;

    @media #{$sm} {
      font-size: 60px;
    }
    @media #{$md} {
      letter-spacing: 6px;
    }
    @media #{$lg} {
      font-size: 80px;
    }

    @media #{$xl} {
      font-size: 99px;
    }
  }
}

.customGap.gap-8 {
  gap: 0 2rem
}