@import '../../styles/utilities';

.tooltip {
  align-items: center;
  background-color: rgba(#ffffff, .8);
  border: 1px solid #000000;
  //display: flex;
  justify-content: center;
  height: auto;
  padding: 10px 5px;
  text-transform: capitalize;
  width: 200px;

  span {
    border-radius: 10px;
    display: block;
    height: 20px;
    margin-right: 8px;
    width: 20px;
  }

  div {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    padding-left: 10px;

    &:first-of-type {
      font-size: 10px;
      font-weight: normal;
    }

    &:nth-child(2) {
      background-color: #B2C0C4;
      font-size: 16px;
      padding: 10px;
    }
  }
}

.legend {
  display: flex;

  li {
    align-items: center;
    display: flex;
    font-size: 12px;
    margin-right: 50px;
    text-transform: uppercase;

    span:nth-child(2) {
      display: block;
      height: 4px;
      margin-left: 10px;
      width: 50px;
    }
  }
}

.filterActive {
  background-color: $primaryBackgound;
}

.pieContainer {
  position: relative;

  .label {
    left: 45px;
    position: absolute;
    text-align: center;
    top: 55px;
  }
}