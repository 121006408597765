@import '../../styles/utilities';

.card {
  //background-color: $darkBackgound;
  border-bottom: 1px solid #ffffff !important;
  border-top: 1px solid;
  color: white;
  cursor: pointer;
  display: block;
  margin-top: -1px;

  &:nth-of-type(1),
  &:nth-of-type(2) {
    margin-top: 0;
  }

  @media (min-width: 1024px) {
    min-height: 16rem;
  }
  text-decoration: none;
}

.innerCard {
  border-top: 10px solid;
  margin-top: 5px;
  padding-top: 30px;

  .cardTitle {
    font-size: 25px;
    line-height: 1;
    margin-bottom: 10px;
    text-decoration: underline;

    @media #{$md} {
      font-size: 44px;
      margin-bottom: 20px;
    }

  }

  p {
    font-size: 16px;
    line-height: 1.2;
    @media #{$md} {
      margin-left: 6px;
    }
  }
}

.cardLastUpdate {
  align-self: end;
  font-size: 16px;
  line-height: 1.2;
  margin-left: 6px;
}