@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'utilities';
@import 'pages';
@import 'homepage';

body {
  font-family: 'DM Sans', sans-serif;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
}

input, select, textarea {
  color: black;
}