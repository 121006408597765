@import '../../../styles/utilities';

.footer_copyright_area {
  background-color: #1C272A;
  color: #ffffff;
  padding: 15px 0;

  .footer_content {
    padding: 0 82px;
  }

  a, p {
    font-size: 12px;
    @media #{$md} {
    font-size: 1rem;
      }
  }
}

.footer_grid {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;

  .footer_grid_inner {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media #{$md} {
      grid-template-columns: 5fr 5fr 10fr;
    }

    @media #{$lg} {
      grid-template-columns: 3fr 3fr 14fr;
    }

    @media #{$_2xl} {
      grid-template-columns: 2fr 2fr 16fr;
    }

    .footer_grid_item {
      position: relative;
      display: inline-block;
      width: 100%;
      vertical-align: top;
      min-height: 1px;
    }
  }
}