@import '../../styles/utilities';

.tableWeekly {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;

  border-color: rgb(156 163 175 / var(--tw-border-opacity));
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  @media screen and (min-width: 1536px) {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  margin-bottom: 100px;
  width: 100%;


  //@media #{$lg} {
  //  width: 150%;
  //}
  //@media #{$xl} {
  //  width: 130%;
  //}
  //@media #{$_2xl} {
  //  width: 100%;
  //}
}

.tableHeader {
  align-self: center;
  font: {
    size: 18px;
    weight: bold;
  }
  display: block;
  margin: 30px 0 5px;


  &:first-child {
    @media screen and (min-width: 1536px) {
      grid-column: span 2 / span 2;
    }
    padding-left: 60px;
  }
}

.tooltip {
  & > div {
    width: auto;
  }

  & > *:nth-child(2) {
    opacity: 1;
    visibility: visible;
  }
}

.active {
  background-color: $primaryBackgound;
}

.cell {
  align-items: flex-start;
  border-top: 1px solid #888;
  display: flex;
  padding-top: 20px;
}

.signalCell {
  font-weight: 800;
  text-transform: capitalize;

  span {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity));
    padding: 0.25rem 3rem 0.25rem 0.75rem;
    width: 10rem;
  }
}

.scoreCell {
  span {
    font: {
      size: 1.875rem;
      weight: 800;
    }
    line-height: 2.25rem;
  }

  .trend {
    margin-top: 12px;
  }
}

.security {
  padding: 0 0 20px 60px;

  span {
    display: block;
  }

  .symbol {
    color: $symbolColor;
    font: {
      size: 20px;
      weight: 600;
    };
    text-decoration: underline;
  }

  .name {
    font: {
      size: 18px;
    };
    margin: 5px 0;
  }

  .sector {
    color: #868686;
    font-weight: 500;
  }
}

.tableShort {
  border-bottom: 1px solid #888;
  margin-bottom: 100px;

  h3 {
    font: {
      size: 25px;
      weight: bold;
    }
    margin-bottom: 20px;
    padding-left: 60px;
    text-transform: uppercase;
  }

  ul {
    display: flex;
    flex-wrap: wrap;

    li {

      width: 100%;

      .security {
        padding: 10px 20px;
      }

      @media screen and (min-width: 768px) {
        width: 25%;

        .security {
          padding: 0 0 0 60px;
        }
      }

      @media screen and (min-width: 1024px) {
        width: 16.66%;
      }

      @media screen and (min-width: 1536px) {
        width: 14.28%;
      }
    }
  }

  .security {
    padding: 0 0 0 60px;
  }

  .name {
    font-size: 14px;
  }
}