@import 'utilities';

.bodyPlaceholder {
  align-items: center;
  display: flex;
  justify-content: center;

  h2 {
    margin: 50px;
    text-align: center;
  }

  p {
    display: block;
    font-size: 80px;
    line-height: .3;
  }

  /* HTML: <div class="loader"></div> */
  /* HTML: <div class="loader"></div> */
  .loader {
    width: fit-content;
    font-size: 80px;
    font-family: monospace;
    font-weight: bold;
    text-transform: uppercase;
    color: #0000;
    -webkit-text-stroke: 1px #000;
    --g: conic-gradient(#000 0 0) no-repeat text;
    background: var(--g) 0, var(--g) 1ch, var(--g) 2ch, var(--g) 3ch, var(--g) 4ch, var(--g) 5ch, var(--g) 6ch;
    animation: l18-0 2s linear infinite alternate,
    l18-1 4s linear infinite;
  }

  .loader:before {
    //content: "Loading";
    font-size: 80px;
  }

  @keyframes l18-0 {
    0% {
      background-size: 1ch 0, 1ch 0, 1ch 0, 1ch 0, 1ch 0, 1ch 0, 1ch 0
    }
    14.28% {
      background-size: 1ch 100%, 1ch 50%, 1ch 0, 1ch 0, 1ch 0, 1ch 0, 1ch 0
    }
    28.57% {
      background-size: 1ch 100%, 1ch 100%, 1ch 50%, 1ch 0, 1ch 0, 1ch 0, 1ch 0
    }
    42.85% {
      background-size: 1ch 100%, 1ch 100%, 1ch 100%, 1ch 50%, 1ch 0, 1ch 0, 1ch 0
    }
    57.14% {
      background-size: 1ch 100%, 1ch 100%, 1ch 100%, 1ch 100%, 1ch 50%, 1ch 0, 1ch 0
    }
    71.43% {
      background-size: 1ch 100%, 1ch 100%, 1ch 100%, 1ch 100%, 1ch 100%, 1ch 50%, 1ch 0
    }
    85.71% {
      background-size: 1ch 100%, 1ch 100%, 1ch 100%, 1ch 100%, 1ch 100%, 1ch 100%, 1ch 50%
    }
    100% {
      background-size: 1ch 100%, 1ch 100%, 1ch 100%, 1ch 100%, 1ch 100%, 1ch 100%, 1ch 100%
    }
  }
  @keyframes l18-1 {
    0%, 50% {
      background-position-y: 100%
    }
    50.01%, to {
      background-position-y: 0
    }
  }
}

.black-page {
  background-color: #253034;
  color: #ffffff;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 128px);
}

.page-hgroup {

}

.big-title {
  font-size: 99px;
  font-weight: lighter;
  letter-spacing: 6px;
  line-height: 99px;
}

.sub-header {
  padding: 50px 0 20px 18px;

  h1 {
    font: {
      size: 65px;
      weight: 500;
    }
    line-height: 1;
    margin-bottom: 5px;
  }
}

.block-row {
  border-bottom: 1px solid #E1E1E1;
  display: flex;
  flex-direction: column;

  @media #{$lg} {
    flex-direction: row;
  }

  .second-row {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;

    @media #{$sm} {
      flex-direction: row;
      padding: 40px 40px 20px 60px;
    }

    @media #{$lg} {
      padding: 0;
      width: 66.66%;
    }
  }

  .block-module {
    border-right: 1px solid #E1E1E1;
    flex-grow: 1;
    width: 100%;

    @media #{$lg} {
      width: 33.33%;
    }

    &:last-of-type {
      border-right: none;
    }

    h2 {
      font-weight: bold;
    }
  }

  .list-table {
    padding: 20px;

    @media #{$sm} {
      padding: 40px 40px 20px 60px;
    }

    li {
      background-color: #F9F9F9;
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
      padding: 2px 6px;

      p:nth-child(2) {
        font-weight: 600;
      }
    }

    .live {
      color: #37D159;
    }

    .inactive {
      color: #FF0000;
    }
  }

  .line-graph {
    width: 100%;

    @media #{$sm} {
      padding: 20px 50px 10px 10px;
      width: 50%;
    }

    .graph-container {
      display: flex;
    }

    .pieContainer {
      margin-left: 30px;
      position: relative;

      .label {
        left: 50px;
        position: absolute;
        text-align: center;
        top: 50px;

        span {
          font-size: 14px;
          font-weight: 600;
        }

        p {
          font: {
            size: 25px;
            weight: 600;
          }
        }
      }

      .today {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 30px;

        img {
          margin-left: 10px;
        }
      }
    }
  }

  .bar-graph {
    width: 100%;

    @media #{$sm} {
      background-color: #F9F9F9;
      padding: 20px 40px 5px 65px;
      width: 50%;
    }

    .signal {
      font: {
        size: 26px;
        weight: 600;
      }
      margin: 20px auto 15px;
      text-transform: uppercase;

      &.strong {
        color: #56B445;
      }
    }

    .bar {
      display: flex;

      li {
        border-right: 1px solid #ffffff;
        flex-grow: 1;
        font-size: 9px;
        height: 24px;
        line-height: 24px;
        text-align: right;
        padding-right: 30px;
        width: 20%;

        &.alert {
          background-color: #F35F5F;
        }

        &.good {
          background-color: #B5FF00;
        }

        &.empty {
          background-color: #ffffff;
        }

        &:first-of-type {
          border-radius: 10px 0 0 10px;
          text-align: left;
          padding-left: 10px;
        }

        &:last-of-type {
          border-radius: 0 10px 10px 0;
          text-align: right;
          padding-right: 10px;
        }

        //&.inactive {
        //  background-image: linear-gradient(to right, #F35F5F, #E7A366);
        //  border-radius: 10px 0 0 10px;
        //}
        //
        //&.little {
        //  background-image: linear-gradient(to right, #E7A366, #DBE56D);
        //}
        //
        //&.medium {
        //  background-image: linear-gradient(to right, #DBE56D, #B5FF00);
        //}
        //
        //&.strong {
        //  background: #B5FF00;
        //}
        //
        //&.high {
        //  background: #ffffff;
        //  border-radius: 0 10px 10px 0;
        //}
      }
    }

    .legend {
      font-size: 12px;
      margin-top: 20px;
      width: 100%;

      a {
        text-decoration: underline;
      }

      li {
        float: left;
        font-size: 14px;
        margin-bottom: 10px;
        width: 50%;

        span {
          margin-right: 10px;
        }
      }
    }
  }
}

.breadcrumb {
  margin: 20px;

  @media #{$sm} {
    margin: 30px 60px;
  }

  ul {
    display: flex;
    font: {
      size: 18px;
      weight: bold;
    }
  }

  li {
    margin-right: 20px;
    position: relative;

    a {
      color: #214EEF;
      text-decoration: underline;
    }
  }

  li::after {
    content: ">";
    position: absolute;
    right: -15px;
  }

  li:last-of-type {
    &::after {
      content: '';
    }
  }
}

.cumulated-return {
  display: flex;
  flex-direction: column;
  margin: 20px;

  @media #{$sm} {
    margin: 30px 30px 30px 60px;
  }

  @media #{$lg} {
    flex-direction: row;
  }

  h2 {
    font: {
      size: 35px;
      weight: bold;
    }
    text-transform: uppercase;
  }

  .graph-container {
    @media #{$lg} {
      margin-right: 30px;
      width: 66.66%;
    }

  }

  .sidebar {
    margin-top: 80px;
    @media #{$lg} {
      margin-top: 0;
      width: 33.33%;
    }

    h4 {
      font: {
        size: 20px;
        weight: bold;
      };
      margin-bottom: 5px;
      padding-left: 6px;
    }

    & > div {
      flex-direction: column;
      display: flex;
    }

    .list-table {
      margin-bottom: 50px;

      li {
        background-color: #F9F9F9;
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
        padding: 2px 6px;

        p:nth-child(2) {
          font-weight: 600;
        }
      }
    }

    .sector-module {
      ul {
        border-bottom: 1px solid #E1E1E1;
      }

      li {
        border-top: 1px solid #E1E1E1;
        display: flex;
        padding: 20px 6px;

        > div {
          align-items: baseline;
          display: flex;
          justify-content: center;
          width: 25%;

          &:first-of-type {
            align-items: center;
            justify-content: start;
            width: 50%;
          }
        }

        span {
          display: block;
        }

        .symbol {
          color: $symbolColor;
          font: {
            size: 20px;
            weight: 600;
          };
          text-decoration: underline;
        }

        .name {
          font: {
            size: 12px;
          };
          margin: 5px 0;
        }

        .signalCell {
          span {
            --tw-bg-opacity: 1;
            background-color: rgb(209 213 219 / var(--tw-bg-opacity));
            font-size: 12px;
            padding: 0.25rem 3rem 0.25rem 0.75rem;
            text-align: left;
          }
        }

        .scoreCell {
          font: {
            size: 18px;
            weight: bold;
          }
        }

        .sector {
          color: #868686;
          font-weight: 500;
        }
      }
    }
  }

}

.sector-list {
  margin: 20px;

  @media #{$sm} {
    margin: 30px 30px 60px 60px;
  }

  h2 {
    font: {
      size: 35px;
      weight: bold;
    }
    text-transform: uppercase;
  }

  ul {
    border-bottom: 1px solid #E1E1E1;
    overflow: hidden;

    li {
      align-items: center;
      border-bottom: 1px solid #E1E1E1;
      display: flex;
      height: 60px;
      justify-content: space-between;

      &:nth-child(2) {
        display: none;
      }

      @media #{$lg} {
        float: left;
        width: 50%;

        &:nth-child(even) {
          padding-left: 60px;
        }

        &:nth-child(2) {
          display: flex;
        }

        &:nth-child(odd) {
          border-right: 1px solid #E1E1E1;
        }

        & > div {
          width: 50%;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      &:first-child {
        border-right: none;
      }

      .label {
        font-size: 14px;
        margin-top: 20px;
      }

      .sector {
        color: #214EEF;
        font: {
          size: 18px;
          weight: 500;
        }
        text-decoration: underline;
      }

      .result {
        font: {
          size: 24px;
          weight: 500;
        }
        padding-right: 10px;

        @media #{$lg} {
          padding-right: 0;
        }

        span {
          font: {
            size: 16px;
            weight: 400;
          }
          margin-left: 10px;
        }
      }
    }
  }
}

.sectorTitle {
  font: {
    size: 24px;
    weight: bold;
  };
  line-height: 50px;
  padding-left: 60px;
  text-transform: uppercase;
}

#legenda {
  font-size: 12px;
  line-height: 1.5;
  margin: 20px 20px 0 20px;

  @media #{$sm} {
    margin: 30px 30px 0 60px;
  }

  .label {
    font-size: 14px;
    line-height: inherit;
    margin-bottom: 10px;
  }

  .note-content {
    border-top: 1px solid #E1E1E1;
    border-bottom: 1px solid #E1E1E1;
    display: grid;
    grid-template-columns: 100%;
    position: relative;

    @media #{$lg} {
      grid-template-columns: 50% 50%;
    }
  }

  .note {
    border-bottom: 1px solid #E1E1E1;
    padding: 20px 0;

    @media #{$lg} {
      padding: 20px 160px 20px 0;
    }


    &:nth-child(even) {
      @media #{$lg} {
        padding-left: 60px;
      }
    }

    &:nth-child(odd) {
      @media #{$lg} {
        border-right: 1px solid #E1E1E1;
      }
    }

    p {
      font-size: inherit;
      line-height: inherit;
    }
  }
}