// Colors
$primaryBackgound: #84C4D6;
$footerBackground: #223035;
$darkBackgound: #37859B;
$lighBackground: #92C2D4;
$darkFontColor: #0A0F17;
$menuColor: #486284;
$symbolColor: #0053F8;

// Responsive
$sm: "screen and (min-width: 640px)";
$md: "screen and (min-width: 768px)";
$lg: "screen and (min-width: 1024px)";
$xl: "screen and (min-width: 1280px)";
$_2xl: "screen and (min-width: 1536px)";

// Fonts
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,400;0,9..40,500;0,9..40,700;1,9..40,100;1,9..40,400;1,9..40,500;1,9..40,700&family=Plus+Jakarta+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');